.burger-menu {
  --burger-menu-radius: 4em;
  position: relative;
  z-index: 100;
  display: block;
  width: var(--burger-menu-radius);
  height: var(--burger-menu-radius);
  background: white;
  box-shadow: 0 0 20px 0px rgba($primary, 0.3);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease-in-out;

  .line {
    position: absolute;
    left: 27.5%;
    width: 45%;
    height: 3px;
    background: $primary;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.5s ease;
    transform: translateY(-50%);

    &:nth-child(1) {
      top: 37.5%;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 62.5%;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $dark;
      transform: translateX(-100%);
      transition: all 0.25s ease;
    }

    @for $i from 2 through 3 {
      &:nth-child(#{$i})::after {
        transition-delay: 0.1s * ($i - 1);
      }
    }
  }

  &:hover {

    .line::after {
      transform: translateX(0);
    }
  }
}
