.rtg-section--home-intro {
  .rtg-section {
    &__bg {
      z-index: -1;

      @include media-breakpoint-down(lg) {
        position: static !important;
      }

      &__inner {
        max-width: 1750px;

        .cyclist {
          @include media-breakpoint-down(sm) {
            width: 200px;
          }
        }

        .return {
          top: 80%;
          left: 72.5%;
          z-index: -1;
        }
      }
    }

    &__content {
      @include media-breakpoint-up(lg) {
        min-height: 100vh;
      }
    }
  }

  h1 {
    letter-spacing: 1.2rem;
  }
}
