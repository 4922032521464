// Bootstrap
$font-family-base: 'Cera',
sans-serif;
$enable-caret: false;
$border-radius-lg: 0.45rem;

// Footer
$min-contrast-ratio: 3.7 !default;

// Colors
$primary: #85893C;
$dark: #1A1A1A;
$weak: lighten($dark, 50%);
$body-bg: #E4E3CF;
$body-color: #1A1A1A;

// Buttons
$input-btn-padding-y-lg: 0.72rem;
$btn-padding-y: 0.75rem;
$btn-padding-x: 2rem;
$btn-line-height: #{'1.5; letter-spacing:0.25rem'};

// Dropdowns
$dropdown-link-color: $primary;
$dropdown-border-width:0;
$dropdown-min-width: var(--dropdown-min-width);
$dropdown-padding-y: 1.75rem;
$dropdown-item-padding-y: 1rem;
$dropdown-item-padding-x: 2.5rem;
$dropdown-border-radius: 1.5rem;

// Input
$input-font-size-lg: 1rem;
$input-padding-y-lg: 0.9rem;
$input-bg: #EEF1F4;
$input-border-color: $input-bg;
$form-check-input-bg: $primary;

// Modal
$modal-content-border-radius: 2.25rem;
$modal-md: 570px;

// Spacers
$spacer: 1rem;
$spacers: (0: 0,
  1: $spacer / 4,
  2: $spacer / 1.35,
  3: $spacer * 1.05,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5.65,
  7: $spacer * 8.5,
);

// Grid
$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  zeta: 1500px);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  zeta: 1420px);


// Border
$border-radius-lg: .6rem;

// Navs
$nav-link-color: #ffffff;
$nav-link-hover-color: darken(#ffffff, 20%);
$nav-pills-border-radius: 20rem;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: #ffffff;

// Accordion
$accordion-bg: #ffffff;
$accordion-button-bg: #ffffff;
$accordion-button-active-bg: #ffffff;
$accordion-button-active-color: $primary;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2'%3E%3Cg id='Group_80' data-name='Group 80' transform='translate(-446.397 -1016.895)'%3E%3Ccircle id='Ellipse_12' data-name='Ellipse 12' cx='1' cy='1' r='1' transform='translate(456.397 1016.895)' fill='%2385893c'/%3E%3Ccircle id='Ellipse_13' data-name='Ellipse 13' cx='1' cy='1' r='1' transform='translate(451.397 1016.895)' fill='%2385893c'/%3E%3Ccircle id='Ellipse_14' data-name='Ellipse 14' cx='1' cy='1' r='1' transform='translate(446.397 1016.895)' fill='%2385893c'/%3E%3C/g%3E%3C/svg%3E%0A") !default;
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2'%3E%3Cg id='Group_80' data-name='Group 80' transform='translate(-446.397 -1016.895)'%3E%3Ccircle id='Ellipse_12' data-name='Ellipse 12' cx='1' cy='1' r='1' transform='translate(456.397 1016.895)' fill='%2385893c'/%3E%3Ccircle id='Ellipse_13' data-name='Ellipse 13' cx='1' cy='1' r='1' transform='translate(451.397 1016.895)' fill='%2385893c'/%3E%3Ccircle id='Ellipse_14' data-name='Ellipse 14' cx='1' cy='1' r='1' transform='translate(446.397 1016.895)' fill='%2385893c'/%3E%3C/g%3E%3C/svg%3E%0A") !default;
