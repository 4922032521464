.rtg-section {
  &__title {

    h1,
    h2 {
      font-weight: 100;
      letter-spacing: 1.25rem;
    }
  }
}
