.rtg-section--profile-calendar {
  .date {
    .dot {
      width: 60px;
      height: 60px
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black;
      z-index: -1;
    }
  }
}
