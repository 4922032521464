@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap');

@font-face {
  font-family: 'Cera';
  font-weight: 100;
  src: url('../fonts/Cera GR Thin.woff') format('woff');
}

@font-face {
  font-family: 'Cera';
  font-weight: 300;
  src: url('../fonts/Cera GR Light.woff') format('woff');
}
