.rtg-section--profile-deeds {
  .accordion-button::after {
    margin-right: 1rem;
    margin-left: 0;
  }

  .form-check {
    z-index: 9;
  }
}
