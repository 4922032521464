:root {
  --menu-height: 125px;

  @include media-breakpoint-up(sm) {
    --bs-gutter-x: 2rem;
    --gutter-x: 2rem;
  }

  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 4rem;
    --gutter-x: 4rem;
    --dropdown-min-width: 22.5rem;
  }
}

::selection {
  color: $white;
  background: $primary;
}

::-moz-selection {
  color: $white;
  background: $primary;
}

.font-1 {
  font-family: $font-family-base;
}

.font-2 {
  font-family: 'Montserrat',
    sans-serif;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.skip-to-content {
  z-index: 9999;
}

.site {
  min-height: 100vh;
}
