.rtg-header {
  pointer-events: none;

  & a {
    pointer-events: all;
  }


  z-index: 999;

  .navbar-brand {
    img {
      @include media-breakpoint-down(md) {
        width: 100px;
      }
    }
  }
}
