.flipclock {

  #clock {
    pointer-events: none;
    user-select: none;
  }

  .span {
    mix-blend-mode: color-dodge;
  }

  .num {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 100px;
    font-size: 4.15rem;
    color: $white;
    background: rgba($dark, 80%);
    box-sizing: border-box;
    border-radius: 18px;
    backface-visibility: hidden;
    margin: 0 0.1rem;
    box-shadow: 0px 5px 5px rgb(0 0 0 / 30%);
    text-align: center;

    @include media-breakpoint-down(sm) {
      // width: 32.5px;
      width: 10vw;
      max-width: 65px;
      margin: 0;
      height: 60px;
      font-size: 2.45rem;
      border-radius: 5px;
    }
  }

  .colon {
    position: relative;
    display: inline-block;
    font-size: 6rem;

    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }

  .base span {
    position: absolute;
    display: block;
    width: 100%;
  }

  .upper {
    position: absolute;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, $primary, $dark 150%);

    border-radius: 15px 15px 0 0;
    overflow: hidden;
    box-sizing: border-box;

    @include media-breakpoint-down(sm) {
      border-radius: 5px 5px 0 0;
    }
  }

  .lower {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, $primary, $dark 150%);
    // background: linear-gradient(0deg, #1A1A1A 0%, $primary 50%, darken($primary, 20%) 100%);

    border-radius: 0 0 15px 15px;
    overflow: hidden;
    box-sizing: border-box;

    @include media-breakpoint-down(sm) {
      border-radius: 0 0 5px 5px;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background: linear-gradient(0deg, rgb(255 255 255 / 0%), $primary 600%);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 60%, #85893C 150%);
    }
  }

  .lower span {
    position: relative;
    top: -100%;
  }
}
